import { Box, Image, VStack } from '@chakra-ui/react';
import SocialIcons from '@ui/components/Atoms/SocialIcons';
import {
  isDesktopAndUpFn,
  useBreakpointFn,
} from '@ui/hooks/chakra/useBreakpointFn';

const SocialSection = () => {
  const isDesktopAndUp = useBreakpointFn(isDesktopAndUpFn);
  return (
    <VStack
      boxSize="full"
      align="center"
      background={{
        base: 'linear-gradient(180deg, #140530 0%, #080314 100%)',
        lg: 'linear-gradient(180deg, #1D0546 0%, #080314 100%)',
      }}
      pt={{ base: '0px', lg: '150px' }}
      pb={{ base: '40px', lg: '150px' }}
    >
      <Box
        position="relative"
        borderRadius="50%"
        backgroundGradient="radial-gradient(ellipse, #EB00FF66 20%, #5A0E7466 40%, transparent 60%)"
      >
        {isDesktopAndUp ? (
          <Box boxSize="full" pt="95px" pb="36px">
            <Image
              width={800}
              src={'/images/follow-us-text.svg'}
              alt="follow-us"
            />
          </Box>
        ) : (
          <Box boxSize="full" pt={6} pb={12}>
            <Image
              width={280}
              src={'/images/follow-us-text-mobile.svg'}
              alt="follow-us"
            />
          </Box>
        )}
      </Box>
      <SocialIcons mode="section" />
    </VStack>
  );
};

export default SocialSection;
