import { Box } from '@chakra-ui/react';

const RoadMapSection = () => {
  return (
    <Box
      w="full"
      height={{
        base: 'calc(100vw / 195 * 421)',
        lg: 'calc(100vw / 1920 * 1243)',
      }}
      backgroundImage={{
        base: 'url(/images/mobile-roadmap-bg-3.png)',
        lg: "url('/images/roadmap-bg-3.png')",
      }}
      backgroundSize="contain"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundColor="#1d0545"
    />
  );
};

export default RoadMapSection;
