/* eslint-disable @ts-intl/ts-intl/no-raw-text */
import { Box, Button, HStack, Link } from '@chakra-ui/react';
import { VvsSwapUrl, VvsToken } from '@ui/config/urls';
import {
  isDesktopAndUpFn,
  useBreakpointFn,
} from '@ui/hooks/chakra/useBreakpointFn';
import { useTranslations } from 'next-intl';

const HeroSection = () => {
  const t = useTranslations();
  const isDesktopAndUp = useBreakpointFn(isDesktopAndUpFn);

  const handleOpenAirDrop = () => {
    window.open('https://t.me/capybara_nation_bot?profile', '_blank');
  };

  return (
    <Box
      w="full"
      height={{
        base: 'calc(100vw / 390 * 400)',
        lg: 'calc(100vw / 384 * 235)',
      }}
      backgroundImage={
        isDesktopAndUp
          ? "url('/images/hero-bg.svg')"
          : "url('/images/mobile-hero-bg.svg')"
      }
      backgroundSize="cover"
      backgroundPosition={['center', 'center', 'center', 'center']}
      backgroundRepeat="no-repeat"
      position="relative"
    >
      <HStack
        position="absolute"
        bottom={{ base: '4%', lg: '4%' }}
        left="50%"
        transform="translateX(-50%)"
      >
        <Button size={{ base: 'sm', lg: 'lg' }} onClick={handleOpenAirDrop}>
          {t('common.Play Now')}
        </Button>
        <Link
          href={VvsSwapUrl(VvsToken.CRO, VvsToken.BARA)}
          target="_blank"
          ml="auto"
        >
          <Button size={{ base: 'sm', lg: 'lg' }}>
            {t('common.TRADE $BARA')}
          </Button>
        </Link>
      </HStack>
    </Box>
  );
};

export default HeroSection;
