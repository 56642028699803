import Layout from '@ui/components/Layout';
import type { NextPage } from 'next';

import HeroSection from './components/HeroSection';
import RoadMapSection from './components/RoadmapSection';
import SocialSection from './components/SocialSection';

export const Page: NextPage = () => {
  return (
    <Layout>
      <HeroSection />
      <RoadMapSection />
      <SocialSection />
    </Layout>
  );
};
